import { useState } from "react";
import { Discount, DiscountTarget } from "../../moderator-types";
import "./Discount.css";

export default function (props: {
    discountAmount?: number;
    target: DiscountTarget;
    label: string;
    onSave: (discount: Discount) => void;
    onDisable: (target: DiscountTarget) => void;
}) {
    const isEnabled = !!props.discountAmount;
    const givenAmount = props.discountAmount ?? 0;
    const [enabled, setEnabled] = useState(isEnabled);
    const [discountAmount, setDiscountAmount] = useState(givenAmount);
    const hasChange = isEnabled !== enabled || givenAmount !== discountAmount;
    let buttonPrompt = hasChange ? "Değişiklikleri Kaydet" : "Değişiklik Yok";
    let buttonEnabled = hasChange;
    if(buttonEnabled && discountAmount == 0)
    {
        buttonPrompt = "İndirim 0 olamaz";
        buttonEnabled = false;
    }
    if(buttonEnabled && discountAmount == 100)
    {
        buttonPrompt = "İndirim 100 olamaz";
        buttonEnabled = false;
    }
    if(buttonEnabled && isNaN(discountAmount))
    {
        buttonPrompt = "Sayı giriniz";
        buttonEnabled = false;
    }
    
    return (
        <div className="discount-container">
            <div className="discount-heading">
                <div className="discount-title">
                    {props.label}
                </div>
                <div className={`discount-toggle ${enabled ? "enabled" : ""}`}
                    onClick={() => {
                        const newState = !enabled;
                        setEnabled(newState);
                        if(newState === false) {
                            props.onDisable(props.target);
                        }
                    }}
                >
                    <div className="discount-toggle-circle"></div>
                </div>
            </div>
            {enabled &&
            <div className={`discount-input-container ${enabled ? "enabled" : ""}`}>
                <div className="discount-input">
                    <div className="discount-percent">%</div>
                    <input
                        type="number"
                        value={discountAmount}
                        min={0}
                        max={99}
                        step={1}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setDiscountAmount(value);
                        }}
                    />
                </div>
                <button onClick={() => {
                    props.onSave({
                        target: props.target,
                        percentage: discountAmount
                    })
                }} className={buttonEnabled ? "enabled" : ""}>
                    {buttonPrompt}
                </button>
            </div>
            }
        </div>
    )
}