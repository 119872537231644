const trTimezoneOffset = 60 * 3;

const offsetDateByMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60 * 1000);
}

const convertBetweenTimezones = (date: Date, fromOffset: number, toOffset: number) => {
    const differenceMinutes = toOffset - fromOffset;
    //add difference to local time
    return offsetDateByMinutes(date, differenceMinutes);
}

export const convertLocalTimeToTurkishTime = (localTime: Date) => {
    const timezoneOffset = -localTime.getTimezoneOffset();
    //add difference to local time
    return convertBetweenTimezones(localTime, timezoneOffset, trTimezoneOffset);
}

export const convertTurkishTimeToLocalTime = (turkishTime: Date) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    return convertBetweenTimezones(turkishTime, trTimezoneOffset, timezoneOffset);
}

const mapUtcTimeToTargetTimezone = (utcTime: Date, targetTimezoneOffset: number) => {
    //let's say we have 20-11-2024 12:00 UTC, we want to convert it to 20-11-2024 12:00 in Turkey
    //we have to add 3 hours to this time
    return offsetDateByMinutes(utcTime, targetTimezoneOffset);
}

const mapTimezoneToUtcTime = (time: Date, timezoneOffset: number) => {
    return offsetDateByMinutes(time, -timezoneOffset);
}

export const mapUtcTimeToTurkishTime = (utcTime: Date) => {
    return mapUtcTimeToTargetTimezone(utcTime, trTimezoneOffset);
}

export const mapTurkishTimeToUtcTime = (turkishTime: Date) => {
    return mapTimezoneToUtcTime(turkishTime, trTimezoneOffset);
}

export const mapUtcTimeToLocalTime = (utcTime: Date) => {
    const localTime = -new Date().getTimezoneOffset();
    return mapUtcTimeToTargetTimezone(utcTime, localTime);
}