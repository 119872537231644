import { on } from "events";
import { TourIncludeIcon } from "../../common-types";
import IncludeIconSelectModal from "../../components/modals/IncludeIconSelectModal";
import useCommon from "../../hooks/useCommon";
import TourIncludesIcon from "./TourIncludesIcon";
import './TourIncludesIconSelect.css';

export default function(props: {
    icon: TourIncludeIcon,
    onChange: (newIcon: TourIncludeIcon) => void
}) {
    const {icon, onChange} = props;
    const common = useCommon();
    return (
        <div onClick={() => {
            common.setModal(<IncludeIconSelectModal onIconSelected={onChange} />, "İkon Seçimi");
        }} className="tour-includes-icon-select">
            <TourIncludesIcon icon={icon} />
        </div>
    )
}