import { useEffect, useState } from "react";
import TransferPricingEntry from "./TransferPricingEntry";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import { ApiErrorResult } from "../../common-types";
import { TransferPriceApplier, TransferPriceApplierType } from "../../moderator-types";
import "./TransferPricing.css";
import useCommon from "../../hooks/useCommon";

export default function (props: {

}) {
    const [autoFocus, setAutoFocus] = useState<{
        index: number,
        target: string
    }>(null!);
    const [transferPriceAppliers, setTransferPriceAppliers] = useState<TransferPriceApplier[]>([]);
    const api = useApi();
    const common = useCommon();
    const qryResult = useQuery({
        queryKey: ['transfer-pricing'],
        queryFn: async () => {
            common.setLoading(true);
            const result = await api.getTransferPrices();
            const asError = result as ApiErrorResult;
            if(asError.isError)
            {
                common.setLoading(false);
                return [];
            }
            const asSuccess = result as TransferPriceApplier[];
            setTransferPriceAppliers(asSuccess);
            common.setLoading(false);
            return asSuccess;
        }
    });
    return (
        <div className="transfer-pricing-panel">
                <div className="transfer-pricing-entries-panel">
                    {transferPriceAppliers.map((entry, index) => {
                        return (
                            <TransferPricingEntry
                                entry={entry}
                                key={index}
                                onChange={(newEntry, changeElement) => {
                                    const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                                    newEntries[index] = newEntry;
                                    setTransferPriceAppliers(newEntries);
                                }}
                                canRemove={true}
                                onRemove={() => {
                                    const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                                    newEntries.splice(index, 1);
                                    setTransferPriceAppliers(newEntries);
                                }}
                                autoFocus={autoFocus && autoFocus.index === index}
                                autoFocusTarget={autoFocus && autoFocus.index === index && autoFocus.target}
                            />
                        )
                    })}
                    <TransferPricingEntry
                        entry={{
                            type: TransferPriceApplierType.Fixed,
                            afterKm: 0,
                            price: 50
                        }}
                        key={-1}
                        onChange={(newEntry, changeElementName, inputElement) => {
                            const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                            newEntries.push(newEntry);
                            const newAutoFocus = {
                                index: newEntries.length - 1,
                                target: changeElementName
                            };
                            inputElement.blur();
                            setAutoFocus(newAutoFocus);
                            setTransferPriceAppliers(newEntries);
                        }}
                        className="placeholder"
                        canRemove={false}
                        autoFocus={false}
                    />
                </div>
                <button onClick={() => {
                    common.setLoading(true);
                    api.setTransferPrices(transferPriceAppliers).then(r => {
                        console.log(r);
                    }).finally(() => {
                        common.setLoading(false);
                        qryResult.refetch();
                    });
                }}>Kaydet</button>
        </div>
    )
}