import { useQuill } from "react-quilljs";
import { Tour } from "../../common-types";
import { useEffect } from "react";
import 'quill/dist/quill.snow.css';
import './TourDetails.css';

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const {getCloned, setTour, tour} = props;
    const {quill, quillRef} = useQuill();
    useEffect(() => {
        if(quill) {
            quill.clipboard.dangerouslyPasteHTML(tour.details);
            quill.on('text-change', (delta, oldDelta, source) => {
                const newHTML = quill.getSemanticHTML();
                const newTour = getCloned();
                newTour.details = newHTML;
                setTour(newTour);
            });
        }

    }, [quill])
    return (
        <div className="tour-details-panel">
            <div ref={quillRef} />
        </div>
    )
}