import { ImageUp, LoaderCircle } from "lucide-react";
import ImagePreviewModal from "../../components/modals/ImagePreviewModal";
import useCommon from "../../hooks/useCommon";
import { useRef, useState } from "react";
import "./TourImageInput.css";
import { useApi } from "../../hooks/useApi";
import { ApiErrorResult } from "../../common-types";
import { ImageUploadResponse } from "../../moderator-types";

export default function(props: {
    onImageUploaded: (imageUrl: string) => void,
    imageUrl: string,
    inputId?: string
}) {
    const api = useApi();
    const imgRef = useRef(null);
    const common = useCommon();
    const hasImage = !!props.imageUrl;
    const [loadingImage, setLoadingImage] = useState(false);
    const imgUploadClass = ["tour-image-upload-btn", loadingImage && "loading"].filter(t => !!t).join(" ");
    return (
        <div className="tour-image-input">
            {hasImage && <img onClick={() => {
                common.setModal(<ImagePreviewModal imageUrl={props.imageUrl} />, "Resim Önizleme")
            }} src={props.imageUrl} className="tour-image-preview" />}
            <button disabled={loadingImage} className={imgUploadClass} onClick={() => {
                imgRef.current?.click();
            }}>
                {loadingImage ? <LoaderCircle className="rotating-icon" /> : <ImageUp />}
                Resim Yükle
            </button>
            <input id={props.inputId} ref={imgRef} type="file" accept="image/*" onChange={async (e) => {
                setLoadingImage(true);
                const file = e.target.files?.[0];
                if(!file)
                {
                    setLoadingImage(false);
                    return;
                }
                const readContent: string|null = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if(!e.target?.result)
                        {
                            resolve(null);
                            return;
                        }
                        resolve(e.target.result as string);
                    }
                    reader.onerror = () => {
                        resolve(null);
                    }
                    reader.onabort = () => {
                        resolve(null);
                    }
                    reader.readAsDataURL(file);
                });
                if(!readContent)
                {
                    setLoadingImage(false);
                    return;
                }
                const fileName = file.name;
                const uploadResult = await api.uploadImage({
                    fileName,
                    data: readContent
                });
                setLoadingImage(false);
                const uploadAsError = uploadResult as ApiErrorResult;
                if(uploadAsError.isError)
                {
                    return;
                }
                const uploadAsSuccess = uploadResult as ImageUploadResponse;
                props.onImageUploaded(uploadAsSuccess.url);
            }} />
        </div>
    )
}