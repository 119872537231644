import React from "react";
import './Modal.css';
import { X } from "lucide-react";
import useCommon from "../hooks/useCommon";

export default function(props: {
    modalProperties: {
        name: string,
        element: React.ReactNode
    }
}) {
    const common = useCommon();
    const className = ["modal-overlay", !!props.modalProperties && "enabled"].filter(t => !!t).join(" ");
    return (
        <div className={className}>
            <div className="modal-container">
                {props.modalProperties?.element}
                <div className="modal-top">
                <div className="modal-title">{props.modalProperties?.name}</div>
                <button onClick={() => common.setModal(null)} className="modal-close">
                    <X />
                </button>
                </div>
            </div>
            <div className="modal-overlay-bg"></div>
        </div>
    )
}