import { Tour } from '../../common-types';
import TourDateEntry from './TourDateEntry';
import TourDateNewEntry from './TourDateNewEntry';
import './TourDates.css';


export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const { getCloned, setTour, tour } = props;
    const entries = tour.dates;
    return (
        <div className='tour-dates-panel'>
            <div className='tour-date-entries-panel'>
                {entries.map((entry, index) => {
                    return (
                        <TourDateEntry
                            key={index}
                            dateEntry={entry}
                            onRemove={() => {
                                const cloned = getCloned();
                                cloned.dates.splice(index, 1);
                                setTour(cloned);
                            }}
                            onUpdate={(updatedEntry) => {
                                const cloned = getCloned();
                                cloned.dates[index] = updatedEntry;
                                setTour(cloned);
                            }}
                            canRemove={true}
                        />
                    )
                })}
            </div>
            <TourDateNewEntry onCreated={(newEntry) => {
                const cloned = getCloned();
                cloned.dates.push(newEntry);
                setTour(cloned);
            }} />
        </div>
    )
}