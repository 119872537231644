import { useContext } from "react"
import getApi from "../api"
import { SessionContext } from "../context/SessionContext"
import { ApiContext } from "../context/ApiContext";

export default function(props) {
    const sessionContext = useContext(SessionContext);
    const api = getApi({
        token: sessionContext.token,
        onUnauthorized: () => {sessionContext.clearToken()},
        onAuthorized: (token: string) => {sessionContext.setToken(token)}
    });
    return (
        <ApiContext.Provider value={{api}}>
            {props.children}
        </ApiContext.Provider>
    )
}