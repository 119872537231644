import { X } from 'lucide-react';
import './TourImageButton.css';
import useCommon from '../../hooks/useCommon';
import ImagePreviewModal from '../../components/modals/ImagePreviewModal';

export default function (props: {
    imageUrl: string,
    onRemove: () => void
}) {
    const common = useCommon();
    const onClick = () => {
        common.setModal(<ImagePreviewModal imageUrl={props.imageUrl} />, "Resim Önizleme")
    }
    return (
        <div onClick={onClick} className='tour-img-button'>
            <img loading="lazy" src={props.imageUrl} alt='Tour Image' />
            <button onClick={(e) => {
                e.stopPropagation();
                props.onRemove();
            }}><X /></button>
        </div>
    )
}