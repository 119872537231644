import { useState } from "react";
import { PanelsContext } from "../../context/PanelsContext";
import { PanelsHook, usePanels } from "../../hooks/usePanels";
import Sidebar from "../../components/bars/Sidebar";
import SidebarButton from "../../components/bars/SidebarButton";
import "./Tickets.css";
import { useCreatePanels } from "../../hooks/useCreatePanels";
import TicketsContainer from "../tickets-container/TicketsContainer";

export default function (props: {
    onTicketEdit: (ticketId: string) => void
    innerPanels: PanelsHook
}) {
    const innerPanels = props.innerPanels;
    const outerPanels = usePanels();
    const changeHandler = (newPanelKey: string) => {
        innerPanels.handleChange(newPanelKey);
    }
    return (
        <PanelsContext.Provider value={innerPanels}>
            <div className="tickets-outer-container">
            <Sidebar>
                <SidebarButton
                    label="Tüm Biletler"
                    enabled={innerPanels.current == "all"}
                    onClick={() => changeHandler("all")}
                ></SidebarButton>
                <SidebarButton
                    label="Atanmış Biletler"
                    enabled={innerPanels.current == "assigned"}
                    onClick={() => changeHandler("assigned")}
                ></SidebarButton>
            </Sidebar>
            <div className="tickets-inner-container">
            <TicketsContainer
                ticketType={innerPanels.current as any}
                handleTicketEdit={props.onTicketEdit}
            />
            </div>
            </div>
        </PanelsContext.Provider>
    )
}