import { useState } from 'react';
import { Tour, TourTimetableEntry } from '../../common-types';
import './TourTimetable.css';
import TourTimetableDay from './TourTimetableDay';

export default function (props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const [autofocusTarget, setAutofocusTarget] = useState<{index: number, element: string} | null>(null);
    const { getCloned, setTour, tour } = props;
    const timetableEntries = tour.timetable;
    const dayGroups = timetableEntries.reduce<{ entry: TourTimetableEntry, index: number }[][]>
    ((acc, entry, index) => {
        if(acc.length < entry.day)
        {
            const diff = entry.day - acc.length;
            for(let i = 0; i < diff; i++)
                acc.push([]);
        }
        acc[entry.day - 1].push({
            entry: entry,
            index
        });
        return acc;
    }, []);
    dayGroups.forEach((group, dayIndex) => {
        group.sort((a, b) => a.entry.time - b.entry.time);
    });
    const getTourCreation = (day: number) => {
        return (newTime?: number, newDescription?: string) => {
            const cloned = getCloned();
            cloned.timetable.push({
                day,
                description: newDescription ?? '',
                time: newTime ?? (13 * 60)
            });
            setTour(cloned);
            if(newDescription)
            {
                setAutofocusTarget({
                    index: cloned.timetable.length - 1,
                    element: newDescription ? 'description' : 'time',
                });
            }
        };
    }
    console.log(autofocusTarget);
    const newIndex = tour.timetable.length;
    return (
        <div className='tour-timetable-panel'>
            {dayGroups.map((group, dayIndex) => {
                return (
                    <TourTimetableDay
                        newIndex={newIndex}
                        key={dayIndex + 1}
                        day={dayIndex + 1}
                        entries={group}
                        onDayCreation={getTourCreation(dayIndex + 1)}
                        onEntryDescriptionChange={(newDescription, index) => {
                            const cloned = getCloned();
                            cloned.timetable[index].description = newDescription;
                            setTour(cloned);
                        }}
                        onEntryTimeChange={(newTime, index) => {
                            const cloned = getCloned();
                            cloned.timetable[index].time = newTime;
                            setTour(cloned);
                        }}
                        autofocusTarget={autofocusTarget}
                        onEntryRemoval={(index) => {
                            const cloned = getCloned();
                            cloned.timetable.splice(index, 1);
                            setTour(cloned);
                        }}
                    />
                )
            })}
            <TourTimetableDay
                newIndex={newIndex}
                key={dayGroups.length + 1}
                day={dayGroups.length + 1}
                entries={[]}
                onDayCreation={getTourCreation(dayGroups.length + 1)}
                onEntryDescriptionChange={(newDescription, index) => {}}
                onEntryTimeChange={(newTime, index) => {}}
                onEntryRemoval={(index) => {}}
                isPlaceholder={true}
            />
        </div>
    )
}