import useCommon from '../../hooks/useCommon';
import './ImagePreviewModal.css';

export default function(props: {
    imageUrl: string,
}) {
    return (
        <>
        <div className="image-preview-modal">
            <img src={props.imageUrl} alt="Preview" />
        </div>
        </>
    )
}