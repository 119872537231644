import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import { useSession } from "../../hooks/useSession";
import useCommon from "../../hooks/useCommon";
import { ApiErrorResult } from "../../common-types";
import { TokenResult } from "../../moderator-types";

export default function (props: {
    errorHandler: (msg: string) => void;
    uname: string;
    pass: string;
}) {
    const api = useApi();
    const session = useSession();
    const common = useCommon();
    useQuery({
        queryKey:["auth"],
        queryFn: async  () => {
            common.setLoading(true);
            const res = await api.authorize(props.uname, props.pass);
            const resAsError = res as ApiErrorResult;
            common.setLoading(false);
            if(resAsError.isError) {
                props.errorHandler("Eror: " + resAsError.message);
                return null;
            }
            const resSuccess = res as TokenResult;
            session.setToken(resSuccess.token);
            return resSuccess.token;
        }
    })
    return (
        <></>
    )
}