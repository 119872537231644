import { useState } from "react";
import { User, UserContactMethod } from "../../common-types";
import "./UserInfo.css";

export default function(props: {
    user: User
}) {
    const user = props.user;
    const [copied, setCopied] = useState<string>(null!);
    let info: { key: string, label: string, value: string }[] = [
        {
            key: "name",
            label: "İsim, Soyisim",
            value: `${user.name} ${user.surname}`
        },
        {
            key: "email",
            label: "E-Posta",
            value: user.email
        },
        {
            key: "phone",
            label: "Telefon",
            value: user.phone
        },
        {
            key: "preferred_contact",
            label: "İletişim Tercihi",
            value: user.preferredContactMethod == UserContactMethod.Email ? "E-Posta" : "WhatsApp"
        }
    ];
    return (<div className="user-info">
        <div className="user-info-title">Kullanıcı Bilgileri</div>
        <div className="user-info-properties">
            {info.map((i) => {
                const isCopied = i.key === copied;
                return (
                    <div key={i.key} className="user-info-property">
                        <span className="user-info-label">{i.label}</span>
                        <span className="user-info-value">{i.value}</span>
                        <button onClick={() => {
                            navigator.clipboard.writeText(i.value);
                            setCopied(i.key);
                        }} className={`user-info-copy ${isCopied ? "copied" : ""}`}>
                            {isCopied ? "Kopyalandı" : "Kopyala"}
                        </button>
                    </div>
                )
            })}
        </div>
    </div>);
}