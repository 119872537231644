import './Sidebar.css';

export default function (props: {
    children?: React.ReactNode;
}) {
    return (
        <div className="sidebar">
            {props.children}
        </div>
    );
}