import { useState } from "react";
import { SessionContext } from "../context/SessionContext";
import * as cookie from 'cookie';

export default function(props) {
    const foundToken = cookie.parse(document.cookie).token ?? null;
    const [tokenValue, setTokenValue] = useState<string | null>(foundToken);
    const clearToken = () => {
        console.log('clearToken');
        setTokenValue(null);
        let cookies = cookie.parse(document.cookie);
        delete cookies.token;
        document.cookie = cookie.serialize('token', '', {
            maxAge: -1 });
    }
    const setToken = (token: string) => {
        setTokenValue(token);
        document.cookie = cookie.serialize('token', token, {
            maxAge: 60 * 60 * 24 * 7 });
    }
    console.log('tokenValue', tokenValue);
    const isLoggedIn = tokenValue !== null;
    return (
        <SessionContext.Provider value={{ isLoggedIn, token: tokenValue, setToken, clearToken }}>
            {props.children}
        </SessionContext.Provider>
    )
}