import { ApiErrorResult, GenericApiResult } from "../../common-types";
import { TokenResult } from "../../moderator-types";

export class RequestSender
{
    onUnauthorized: () => void = null!;
    token: string = null!;
    baseUrl: string = 'http://localhost:3152';
    constructor(onUnauthorized: () => void, baseUrl: string)
    {
        this.baseUrl = baseUrl;
        this.onUnauthorized = onUnauthorized;
    }

    async obtainToken(username: string, password: string)
    {
        const tokenResult = await this.sendRequest<TokenResult>({
            method: "POST",
            body: {
                username,
                password
            },
            path: "auth/login"
        });
        return tokenResult;
    }

    createUrlFor(path: string, query?: {[key: string]: string})
    {
        query ??= {};
        if(!path.startsWith("/"))
            path = "/" + path;
        if(Object.keys(query).length > 0)
        {
            path += "?";
            path += Object.entries(query).map(([k, v]) => `${k}=${v}`).join("&");
        }
        return `${this.baseUrl}${path}`;
    }

    async sendRequest<T>(options: RequestOptions): Promise<GenericApiResult<T>>
    {
        const method = options.method ?? "GET";
        const query = options.query ?? {};
        const url = this.createUrlFor(options.path, query);
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token ? `Bearer ${this.token}` : ''
                },
                body: options.body ? JSON.stringify(options.body) : undefined
            });

            if (response.status === 401)
            {
                this.token = null!;
                this.onUnauthorized();
            }

            const jsonBody = await response.json();
            return jsonBody as GenericApiResult<T>;
        } catch (e) {
            const err: ApiErrorResult = {
                isError: true,
                message: "Sunucu Hatası!"
            }
            return err;
        }

    }
}

export type RequestOptions = {
    method?: "GET" | "POST" | "PUT" | "UPDATE" | "DELETE";
    path: string;
    query?: {[key: string]: string},
    body?: any
}