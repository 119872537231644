import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi"
import useCommon from "../../hooks/useCommon";
import { usePanels } from "../../hooks/usePanels";
import { ApiErrorResult, Tour } from "../../common-types";
import { useState } from "react";
import Sidebar from "../../components/bars/Sidebar";
import SidebarButton from "../../components/bars/SidebarButton";
import { useCreatePanels } from "../../hooks/useCreatePanels";
import { Ban, Check, Save, X } from "lucide-react";
import "./EditTour.css";
import Panel from "../../components/slider/Panel";
import { PanelsContext } from "../../context/PanelsContext";
import PanelContainer from "../../components/slider/PanelContainer";
import TourGeneralPanel from "../../edit-tour-panels/tour-general-panel/TourGeneralPanel";
import YesNoModal from "../../components/modals/YesNoModal";
import TourIncludes from "../../edit-tour-panels/tour-includes-panel/TourIncludes";
import TourTimetable from "../../edit-tour-panels/tour-timetable-panel/TourTimetable";
import TourImages from "../../edit-tour-panels/tour-images/TourImages";
import TourDates from "../../edit-tour-panels/tour-dates/TourDates";
import TourDetails from "../../edit-tour-panels/tour-details/TourDetails";
import {parse, HTMLElement} from 'node-html-parser';
import { ImageUploadResponse } from "../../moderator-types";

export default function(props: {
    tourId: number,
}) {
    const api = useApi();
    const common = useCommon();
    const panels = usePanels();
    const [tourData, setTourData] = useState<Tour>(null!);
    const qryResult = useQuery({
        queryKey: ["edit-tour", props.tourId, !!tourData],
        queryFn: async () => {
            common.setLoading(true);
            const tour = await api.getTour(props.tourId);
            common.setLoading(false);
            const asError = tour as ApiErrorResult;
            if(asError.isError) {
                panels.popPanel();
                return;
            }
            setTourData(tour as Tour);
            return tour as Tour;
        },
        refetchOnMount: true,
        staleTime: 0
    });
    const getClonedTour = () => {
        return JSON.parse(JSON.stringify(tourData)) as Tour;
    }
    const setTourFromCloned = (cloned: Tour) => {
        setTourData(cloned);
    }
    const isFetched = qryResult.isFetched && !qryResult.isFetching && !!qryResult.data;
    const innerPanels = useCreatePanels("general");
    return (
        <>
            {isFetched && <div className="edit-tour">
                <Sidebar>
                    <SidebarButton
                        label="Genel Bilgiler"
                        onClick={() => {
                            innerPanels.handleChange("general");
                        }}
                        enabled={innerPanels.current === "general"}
                    />
                    <SidebarButton
                        label="Tur Ayrıntıları"
                        onClick={() => {
                            innerPanels.handleChange("details");
                        }}
                        enabled={innerPanels.current === "details"}
                    />
                    <SidebarButton
                        label="Tur İçerikleri"
                        onClick={() => {
                            innerPanels.handleChange("includes");
                        }}
                        enabled={innerPanels.current === "includes"}
                    />
                    <SidebarButton
                        label="Tur Programı"
                        onClick={() => {
                            innerPanels.handleChange("timetable");
                        }}
                        enabled={innerPanels.current === "timetable"}
                    />
                    <SidebarButton
                        label="Görseller"
                        onClick={() => {
                            innerPanels.handleChange("images");
                        }}
                        enabled={innerPanels.current === "images"}
                    />
                    <SidebarButton
                        label="Tarihler"
                        onClick={() => {
                            innerPanels.handleChange("dates");
                        }}
                        enabled={innerPanels.current === "dates"}
                    />
                    <button
                        className="apply-tour-changes-btn"
                        onClick={async () => {
                            common.setLoading(true);
                            const clonedTour = getClonedTour();
                            if(clonedTour.price.discountedAmount !== undefined
                                && clonedTour.price.discountedAmount >= clonedTour.price.amount) {
                                    delete clonedTour.price.discountedAmount;
                            }
                            try {
                                const parsedHTML = parse(clonedTour.details);
                                const images = parsedHTML.querySelectorAll("img");
                                let ix = 0;
                                for(const img of images)
                                {
                                    const src = img.getAttribute("src");
                                    if(!src)
                                        continue;
                                    if(src.startsWith("data:image"))
                                    {
                                        const ext = src.split(";")[0].split("/")[1] ?? "png";
                                        const newSrc = await api.uploadImage({
                                            fileName: `${clonedTour.id}-img-${ix}.${ext}`,
                                            data: src
                                        });
                                        const asError = newSrc as ApiErrorResult;
                                        if(asError.isError)
                                            continue;
                                        const asSuccess = newSrc as ImageUploadResponse;
                                        img.setAttribute("src", asSuccess.url);
                                        ix++;
                                    }
                                }
                                clonedTour.details = parsedHTML.toString();
                            } catch(e) {
                            }
                            const res = await api.editTour(clonedTour);
                            const asError = res as ApiErrorResult;
                            if(asError.isError) {
                                console.error(asError.message);
                                common.setLoading(false);
                                return;
                            }
                            qryResult.refetch();
                            common.setLoading(false);
                        }}
                    >
                        <Save />
                        Değişiklikleri Kaydet
                    </button>
                    <div className="sidebar-spacing"></div>
                    <button
                        className="delete-tour-btn"
                        onClick={async () => {
                            //common.setLoading(true);
                            //await api.removeTour(props.tourId);
                            //panels.popPanel();
                            //common.setLoading(false);
                            common.setModal(
                                <YesNoModal
                                    prompt="Turu silmek istediğinden emin misin?"
                                    noLabel="İptal et"
                                    yesLabel="Evet, sil"
                                    yesClassName="btn-red"
                                    yesIcon={<Check />}
                                    noIcon={<X />}
                                    onYes={async () => {
                                        common.setModal(null);
                                        common.setLoading(true);
                                        await api.removeTour(props.tourId);
                                        panels.popPanel();
                                        common.setLoading(false);
                                    }}
                                    onNo={() => {
                                        common.setModal(null);
                                    }}
                                />, "Turu Sil"
                            )
                        }}
                    >
                        <Ban />
                        Turu Sil
                    </button>
                </Sidebar>
                <PanelsContext.Provider value={innerPanels}>
                    <PanelContainer
                        className="edit-tour-panels"
                        currentPanelKey={innerPanels.current}
                        panelsInner={[
                            {
                                component: <TourGeneralPanel
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "general"
                            },
                            {
                                component: <TourDetails
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "details"
                            },
                            {
                                component: <TourIncludes
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "includes"
                            },
                            {
                                component: <TourTimetable
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "timetable"
                            },
                            {
                                component: <TourImages
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "images"
                            },
                            {
                                component: <TourDates
                                    tour={tourData}
                                    getCloned={getClonedTour}
                                    setTour={setTourFromCloned}
                                />,
                                key: "dates"
                            }
                        ]}
                        onPanelChange={(npk) => {}}
                    />
                </PanelsContext.Provider>
            </div>}
        </>
    )
}