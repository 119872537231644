import { createContext } from "react";

export const PanelsContext = createContext<{
    handleChange: (key: string) => void;
    current: string;
    pushPanel: (key: string) => void;
    popPanel: () => void;
}
>({
    handleChange: null!,
    current: null!,
    pushPanel: null!,
    popPanel: null!
});