import { TourIncludeIcon } from '../../common-types';
import TourIncludesIcon from '../../edit-tour-panels/tour-includes-panel/TourIncludesIcon';
import useCommon from '../../hooks/useCommon';
import './IncludeIconSelectModal.css';

export default function(props: {
    onIconSelected: (icon: TourIncludeIcon) => void
}) {
    const allIconKeys = Object.values(TourIncludeIcon);
    const common = useCommon();
    return (
        <div className='tour-include-icon-select-modal'>
            {allIconKeys.map((iconKey) => {
                return (
                    <button onClick={() => {
                        props.onIconSelected(iconKey as TourIncludeIcon);
                        common.setModal(null);
                    }} key={iconKey} className='include-icon-select-btn'>
                        <TourIncludesIcon icon={iconKey as TourIncludeIcon} />
                    </button>
                )
            })}
        </div>
    )
}