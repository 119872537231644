import { X } from 'lucide-react';
import { TourDateEntry, TourDateEntryType, TourDateEveryDay, TourDateEveryMonth, TourDateEveryWeek, TourDateSingle } from '../../common-types';
import './TourDateEntry.css';
import Toggle from '../../components/toggle/Toggle';
import { mapUtcTimeToLocalTime } from '../../utils/tr-timezone';

export default function (props: {
    dateEntry: TourDateEntry,
    onRemove?: () => void,
    onUpdate: (dateEntry: TourDateEntry) => void,
    canRemove?: boolean,
    children?: any,
    className?: string
}) {
    const { dateEntry, onRemove, onUpdate } = props;
    const entryData = dateEntry.data;
    const dataAsSingle = entryData as TourDateSingle;
    const dataAsEveryDay = entryData as TourDateEveryDay;
    const dataAsEveryWeek = entryData as TourDateEveryWeek;
    const dataAsEveryMonth = entryData as TourDateEveryMonth;
    const defaults = {
        [TourDateEntryType.Single]: {
            date: mapUtcTimeToLocalTime(new Date()).toISOString().slice(0, 16)
        },
        [TourDateEntryType.EveryDay]: {
            time: 8 * 60
        },
        [TourDateEntryType.EveryWeek]: {
            weekday: 0,
            time: 8 * 60
        },
        [TourDateEntryType.EveryMonth]: {
            day: 1,
            time: 8 * 60
        }
    }
    const typeMap = {
        [TourDateEntryType.Single]: "Tek Tarih",
        [TourDateEntryType.EveryDay]: "Günde bir",
        [TourDateEntryType.EveryWeek]: "Haftada bir",
        [TourDateEntryType.EveryMonth]: "Ayda bir"
    }
    const padNumber = (num: number) => {
        return num.toString().padStart(2, '0');
    }
    const formatTimeNumberToString = (time: number) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return `${padNumber(hours)}:${padNumber(minutes)}`;
    }
    const timeStrToNumber = (timeStr: string) => {
        try {

            const [hours, minutes] = timeStr.split(':').map((str) => parseInt(str));
            return hours * 60 + minutes;
        } catch (e) {
            return 8 * 60;
        }
    }
    const days = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
    let singleDate: Date = null;
    if(dateEntry.type === TourDateEntryType.Single)
    {
        console.log(dataAsSingle.date);
        singleDate = new Date(dataAsSingle.date);
        const currentDate = new Date();
    }
    const dataInputs = {
        [TourDateEntryType.Single]: (<>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Tarih</div>
                <input type='datetime-local' value={dataAsSingle.date} onChange={(e) => {
                    console.log(e.target.value);
                    try
                    {

                        const isoTime = new Date(e.target.value).toISOString();
                        console.log(isoTime);
                    }
                    catch {}
                    onUpdate({
                        type: TourDateEntryType.Single,
                        data: {
                            date: e.target.value
                        }
                    })
                }} />
            </div>
        </>),
        [TourDateEntryType.EveryDay]: (<>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Saat</div>
                <input type='time' value={formatTimeNumberToString(dataAsEveryDay.time)} onChange={(e) => {
                    onUpdate({
                        type: TourDateEntryType.EveryDay,
                        data: {
                            time: timeStrToNumber(e.target.value)
                        }
                    })
                }} />
            </div>
        </>),
        [TourDateEntryType.EveryWeek]: (<>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Gün</div>
                <select value={dataAsEveryWeek.weekday} onChange={(e) => {
                    onUpdate({
                        type: TourDateEntryType.EveryWeek,
                        data: {
                            weekday: parseInt(e.target.value),
                            time: dataAsEveryWeek.time
                        }
                    })
                }}>
                    {Array.from({ length: 7 }, (_, i) => i).map((day) => {
                        return (
                            <option key={day} value={day}>{days[day]}</option>
                        )
                    })}
                </select>
            </div>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Saat</div>
                <input type='time' value={formatTimeNumberToString(dataAsEveryDay.time)} onChange={(e) => {
                    onUpdate({
                        type: TourDateEntryType.EveryWeek,
                        data: {
                            weekday: dataAsEveryWeek.weekday,
                            time: timeStrToNumber(e.target.value)
                        }
                    })
                }} />
            </div>
        </>),
        [TourDateEntryType.EveryMonth]: (<><>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Gün</div>
                <select value={dataAsEveryWeek.weekday} onChange={(e) => {
                    onUpdate({
                        type: TourDateEntryType.EveryMonth,
                        data: {
                            day: parseInt(e.target.value),
                            time: dataAsEveryMonth.time
                        }
                    })
                }}>
                    {Array.from({ length: 28 }, (_, i) => i).map((day) => {
                        return (
                            <option key={day + 1} value={day + 1}>{day +1}</option>
                        )
                    })}
                </select>
            </div>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Saat</div>
                <input type='time' value={formatTimeNumberToString(dataAsEveryDay.time)} onChange={(e) => {
                    onUpdate({
                        type: TourDateEntryType.EveryMonth,
                        data: {
                            day: dataAsEveryMonth.day,
                            time: timeStrToNumber(e.target.value)
                        }
                    })
                }} />
            </div>
        </></>)

    }
    return (
        <div className={['tour-date-entry', props.className].filter(t => !!t).join(' ')}>
            <div className='tour-date-entry-columns'>
            <div className='tour-date-entry-row'>
                <div className='tour-date-entry-label'>Sıklık</div>
                <select value={dateEntry.type} onChange={(e) => {
                    const newType: TourDateEntryType = e.target.value as TourDateEntryType;
                    const defaultValue = defaults[newType];
                    const newData = {...defaultValue};
                    for(const key in entryData)
                    {
                        if(key in newData)
                            newData[key] = entryData[key];
                    }
                    onUpdate({
                        type: newType,
                        data: newData as any
                    });
                }}>
                    {Object.entries(TourDateEntryType).map(([key, value]) => {
                        return (
                            <option key={key} value={value}>{typeMap[value]}</option>
                        )
                    })}
                </select>
            </div>
            {dataInputs[dateEntry.type]}
            <div className='tour-date-entry-row exclude'>
                <div className='tour-date-entry-label'>Bu Tarihte Olmayacak</div>
                <Toggle value={dateEntry.exclude ?? false} onChange={(value) => {
                    onUpdate({
                        ...dateEntry,
                        exclude: value
                    })
                }} />
            </div>
            </div>
            {props.canRemove && <button className='remove-button' onClick={onRemove}>
                <X/> Sil
            </button>}
            {props.children}
        </div>
    )
}