import { Tour } from '../../common-types';
import TourAddImageButton from './TourAddImageButton';
import TourImageButton from './TourImageButton';
import './TourImages.css';

export default function (props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const { getCloned, setTour, tour } = props;
    const images = tour.images;
    const onImageRemove = (index: number) => {
        const cloned = getCloned();
        cloned.images.splice(index, 1);
        setTour(cloned);
    }
    const onImageUpload = (newUrl: string) => {
        const includes = images.includes(newUrl);
        if(includes)
            return;
        const cloned = getCloned();
        cloned.images.push(newUrl);
        setTour(cloned);
    }
    return (
        <div className='tour-images-panel'>
            <TourAddImageButton onImageAdd={onImageUpload} />
            {images.map((imageUrl, index) => {
                return (
                    <TourImageButton
                        key={imageUrl}
                        onRemove={() => onImageRemove(index)}
                        imageUrl={imageUrl}
                    />
                )
            })}
        </div>
    )
}
