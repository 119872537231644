import './YesNoModal.css';

export default function(props: {
    prompt: string,
    yesIcon?: React.ReactNode
    yesLabel?: string,
    noIcon?: React.ReactNode,
    noLabel?: string,
    yesClassName?: string,
    noClassName?: string,
    containerClassName?: string,
    promptClassName?: string,
    onYes: () => void,
    onNo: () => void
}) {
    const yesLabel = props.yesLabel ?? "Evet";
    const noLabel = props.noLabel ?? "Hayır";
    const yesClassName = ["prompt-button", "yes", props.yesClassName].filter(t => !!t).join(" ");
    const noClassName = ["prompt-button", "no", props.noClassName].filter(t => !!t).join(" ");
    const containerClassName = ["yes-no-modal", props.containerClassName].filter(t => !!t).join(" ");
    const promptClassName = ["prompt", props.promptClassName].filter(t => !!t).join(" ");
    return (
        <div className={containerClassName}>
            <div className={promptClassName}>{props.prompt}</div>
            <div className="buttons">
                <button className={yesClassName} onClick={props.onYes}>
                    {props.yesIcon}
                    {yesLabel}
                </button>
                <button className={noClassName} onClick={props.onNo}>
                    {props.noIcon}
                    {noLabel}
                </button>
            </div>
        </div>
    )
}