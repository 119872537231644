import { useQuery } from "@tanstack/react-query";
import { useApi } from "../hooks/useApi";
import Spinner from "./Spinner";
import { ApiErrorResult } from "../common-types";

export default function UserBlock() {
    const api = useApi();
    const qry = useQuery({
        queryKey: ["user-data-fetch"],
        queryFn: async () => {
            const uData = await api.getUserData();
            const uDataAsError = uData as ApiErrorResult;
            if(uDataAsError.isError) {
                return {username: "NOTFOUND"};
            }
            const uDataSuccess = uData as {username: string};
            return uDataSuccess;
        }
    });
    return (
        <div className="user-block">
            {qry.isLoading ? <Spinner />: 
            <div className="user-greet">
                Merhaba, <strong>{qry.data.username}</strong>
            </div>}
        </div>
    )
}