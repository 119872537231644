import { useState } from "react";
import { CommonContext } from "../context/CommonContext";
import Loading from "../overlays/Loading";
import Modal from "../overlays/Modal";

export default function(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState<{
        name: string,
        element: React.ReactNode
    }>(null!);
    const [errorMessage, setError] = useState(null! as string);
    return (<CommonContext.Provider value={
        {
            isLoading: loading,
            setLoading,
            errorMessage,
            setError,
            setModal: (elem, mname) => {
                if(!elem) {
                    setModal(null!);
                }
                else
                    setModal({
                        name: mname ?? "",
                        element: elem
                    });
            }
        }
    }>
        <Loading enabled={loading} />
        <Modal modalProperties={modal} />
        <div className="common-container">
        {props.children}
        </div>
    </CommonContext.Provider>)
}