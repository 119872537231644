import { useState } from "react";
import DashboardTop from "../../components/bars/DashboardTop";
import UserBlock from "../../components/UserBlock";
import { useSession } from "../../hooks/useSession";
import PanelContainer from "../../components/slider/PanelContainer";
import { PanelsContext } from "../../context/PanelsContext";
import Tickets from "../../dashboard-panels/tickets/Tickets";
import "./Dashboard.css";
import { useCreatePanels } from "../../hooks/useCreatePanels";
import TicketInfo from "../../components/ticket-info/TicketInfo";
import EditTicket from "../../dashboard-panels/edit-ticket/EditTicket";
import Prices from "../../dashboard-panels/prices/Prices";
import Tours from "../../dashboard-panels/tours/Tours";
import EditTour from "../../dashboard-panels/edit-tour/EditTour";

export default function(props) {
    const [editingTicketId, setEditingTicketId] = useState<string>(null);
    const [editingTourId, setEditingTourId] = useState<number>(null);
    const panelUtils = useCreatePanels("tickets");
    const ticketsInnerPanels = useCreatePanels("all");
    const pricesInnerPanels = useCreatePanels("discounts");
    return (
        <PanelsContext.Provider value={panelUtils}>
        <div className="dashboard">
            <DashboardTop />
            <div className="dashboard-inner">
                <PanelContainer
                    currentPanelKey={panelUtils.current}
                    panelsInner={
                        [
                            {
                                component: <Tickets
                                    onTicketEdit={(tid) => {
                                        setEditingTicketId(tid);
                                        panelUtils.pushPanel("edit-ticket");
                                    }}
                                    innerPanels={ticketsInnerPanels}
                                />,
                                key: "tickets"
                            },
                            {
                                component: <Tours onTourEdit={(tid) => {
                                    setEditingTourId(tid);
                                    panelUtils.pushPanel("edit-tour");
                                }}  />,
                                key: "tours"
                            },
                            {
                                component: <Prices innerPanels={pricesInnerPanels}/>,
                                key: "prices"
                            },
                            {
                                component: <EditTicket ticketId={editingTicketId} />,
                                key: "edit-ticket"
                            },
                            {
                                component: <EditTour tourId={editingTourId} />,
                                key: "edit-tour"
                            }
                        ]
                    }
                    onPanelChange={(newPanelKey) => {
                        console.log("panel changed", newPanelKey);
                    }}
                >
                </PanelContainer>
            </div>
        </div>
        </PanelsContext.Provider>
    );
}