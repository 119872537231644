import { useEffect, useState } from 'react';
import './Panel.css';
export default function Panel(props: {
    className?: string,
    animation: string,
    children: React.ReactNode
}) {
    const [animState, setAnimState] = useState(props.animation);
    const className = `panel-item ${props.className ?? ""} ${animState}`;
    return (
        <div className={className} onAnimationEnd={(anim) => {
            const sanitizedAnimName = `${animState}-anim`;
            if(anim.animationName === sanitizedAnimName) {
                console.log("Animation ended");
                setAnimState("no-move");
            }
        }}>
            {props.children}
        </div>
    )
}