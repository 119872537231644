import { useRef, useState } from 'react';
import './TourAddImageButton.css';
import { handleImageUpload } from '../../utils/image-upload';
import { useApi } from '../../hooks/useApi';
import Spinner from '../../components/Spinner';
import { ImageUp, Loader, LoaderCircle } from 'lucide-react';

export default function (props: {
    onImageAdd: (imgUrl: string) => void
}) {
    const api = useApi();
    const inputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const handleImgUpload = async (file: File) => {
        setIsUploading(true);
        await handleImageUpload(file, api, {
            onAnyError: () => {
                setIsUploading(false);
            },
            onSuccess: (url) => {
                props.onImageAdd(url);
                setIsUploading(false);
            }
        });
        inputRef.current.value = '';
    }
    return (<>
    <button onClick={() => {
        inputRef.current?.click();
    }} className='tour-img-button tour-add-img-button'>
        {isUploading ? <LoaderCircle className='rotating-icon' /> : <ImageUp />}
        {isUploading ? 'Yükleniyor' : 'Resim Ekle'}
    </button>
    <input ref={inputRef} style={{
        display: 'none'
    }} type='file' accept='image/*' onChange={(e) => {
        const file = e.target.files?.[0];
        if(!file)
            return;
        handleImgUpload(file);
    }} />
    </>)
}