import type { Ticket } from "./types/ticket";
import { RequestSender } from "./request/request-sender";
import { GenericApiResult, TicketStatusType, Tour } from "../common-types";
import { AssignmentAcquireOrDropRequest, AssignmentAcquireOrDropResponse, Discount, DiscountApplyRequest, Discounts, DiscountTarget, ImageUploadRequest, ImageUploadResponse, ModeratorTicket, ModeratorTicketListEntry, TicketStatusChangeRequest, TourCreationResult, TransferPriceApplier, TransferPriceValidateResponse } from "../moderator-types";

const getApi = (props: {
    token: string,
    onUnauthorized: () => void,
    onAuthorized: (token: string) => void
}) => {
    console.log("apiurl:", process.env.REACT_APP_API_URL);
    const requestSender = new RequestSender(props.onUnauthorized, process.env.REACT_APP_API_URL);
    requestSender.token = props.token;
    return {
        requestSender: requestSender,
        authorize: async (username: string, password: string) => {
            const tokenResult = await requestSender.obtainToken(username, password);
            return tokenResult;
        },
        authorizeWithToken: async (token: string) => {
            requestSender.token = token;
        },
        requestAssignment: async (ticketId: string) => {
            const req: AssignmentAcquireOrDropRequest = {
                ticketId
            }
            const res = await requestSender.sendRequest<AssignmentAcquireOrDropResponse>({
                method: "POST",
                path: "/admin/tickets/acquire",
                body: req
            });
            return res;
        },
        changeTicketStatus: async (ticketId: string, newStatus: TicketStatusType, message?: string) => {
            const req: TicketStatusChangeRequest = {
                ticketId,
                status: newStatus,
                message
            };
            return await requestSender.sendRequest<GenericApiResult<{success:true}>>({
                method: "POST",
                path: "/admin/tickets/update-status",
                body: req
            });
        },
        releaseAssignment: async (ticketId: string) => {
            const req: AssignmentAcquireOrDropRequest = {
                ticketId
            }
            const res = await requestSender.sendRequest<AssignmentAcquireOrDropResponse>({
                method: "POST",
                path: "/admin/tickets/release",
                body: req
            });
            return res;
        },
        getAssignedTickets: async () => {
            return await requestSender.sendRequest<ModeratorTicketListEntry[]>({
                path: "/admin/tickets/assigned"
            });
        },
        getTicketList: async () => {
            return await requestSender.sendRequest<ModeratorTicketListEntry[]>({
                path: "/admin/tickets/all"
            })
        },
        getTicketDetails: async (ticketId: string) => {
            return await requestSender
            .sendRequest<ModeratorTicket>({
                method: "GET",
                path: "admin/tickets/single",
                query: {ticketId}
            });
        },
        getUserData: async () => {
            return await requestSender.sendRequest<{
                username: string
            }>({
                path: "/auth/user-data"
            });
        },
        getDiscounts: async () => {
            return await requestSender.sendRequest<Discounts>({
                path: "/admin/discounts"
            });
        },
        applyDiscount: async (discount: Discount) => {
            const req: DiscountApplyRequest = discount;
            return await requestSender.sendRequest<{succes: true}>({
                method: "POST",
                path: "/admin/discounts/apply",
                body: req
            });
        },
        removeDiscount: async (target: DiscountTarget) => {
            return await requestSender.sendRequest<{success: true}>({
                method: "POST",
                path: "/admin/discounts/clear",
                body: {
                    target
                }
            });
        },
        getTours: async () => {
            return await requestSender.sendRequest<Tour[]>({
                method: "GET",
                path: "/admin/tours/list",
            });
        },
        getTour: async (tourId: number) => {
            return await requestSender.sendRequest<Tour>({
                method: "GET",
                query: {id: tourId.toString()},
                path: "/admin/tours/single"
            });
        },
        editTour: async (tour: Tour) => {
            return await requestSender.sendRequest<{success: true}>({
                method: "POST",
                body: {tour},
                path: "/admin/tours/edit"
            });
        },
        createTour: async () => {
            return await requestSender.sendRequest<TourCreationResult>({
                method: "GET",
                path: "/admin/tours/new"
            })
        },
        removeTour: async (tourId: number) => {
            return await requestSender.sendRequest<{success: true}>({
                method: "DELETE",
                query: {id: tourId.toString()},
                path: "/admin/tours/delete"
            });
        },
        uploadImage: async (uploadRequest: ImageUploadRequest) => {
            return await requestSender.sendRequest<ImageUploadResponse>({
                method: "POST",
                body: uploadRequest,
                path: "admin/image/upload"
            });
        },
        getTransferPrices: async () => {
            return await requestSender.sendRequest<TransferPriceApplier[]>({
                method: "GET",
                path: "/admin/transfer-prices"
            });
        },
        setTransferPrices: async (prices: TransferPriceApplier[]) => {
            return await requestSender.sendRequest<TransferPriceValidateResponse>({
                method: "POST",
                body: prices,
                path: "/admin/transfer-prices"
            });
        }
    }
}

export type Api = ReturnType<typeof getApi>;

export default getApi;