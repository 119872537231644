import { Ticket, TicketListEntry, TicketStatusType, Tour } from "./common-types";

export type ModeratorTicketListEntry = {
    entry: TicketListEntry;
    assignment: "none" | "self" | "other";
    assignedTo?: string;
}

export enum DiscountTarget {
    TourAll = "tour_all",
    TransferAll = "transfer_all",
}

export type Discount = {
    target: DiscountTarget;
    percentage: number;
}

export enum TransferPriceApplierType {
    Fixed = "fixed",
    Expression = "expression",
}

export type TransferPriceApplier = {
    afterKm: number;
} & ({
    type: TransferPriceApplierType.Fixed;
    price: number;
} | {
    type: TransferPriceApplierType.Expression;
    expression: string;
});

export type ImageHashCheckRequest = {
    hash: string;
}

export type ImageHashCheckResponse = {
    exists: false;
} | {exists: true, url: string};

export type ImageUploadRequest = {
    data: string;
    fileName: string;
}

export type ImageUploadResponse = {
    url: string;
}

export type TicketStatusChangeRequest = {
    ticketId: string;
    status: TicketStatusType;
    message?: string;
}

export type TransferPriceValidateRequest = {
    applier: TransferPriceApplier
}

export type TransferPriceValidateResponse = {
    success: true;
}

export type TourEditRequest = {
    tour: Tour;
}

export type TourEntry = Omit<Tour, "id">;

export type DiscountApplyRequest = {
    target: DiscountTarget;
    percentage: number;
}

export type GenericSuccessResponse = {
    success: true;
}

export type DiscountRemoveRequest = {
    target: DiscountTarget;
}

export type Discounts = {
    tourAll?: Discount;
    transferAll?: Discount;
}

export type ModeratorTicket = {
    ticket: Ticket;
    assignedSelf: boolean;
}

export type TokenResult = {
    token: string;
}

export type AssignmentAcquireOrDropRequest = {
    ticketId: string;
}

export type AssignmentAcquireOrDropResponse = {
    success: true;
}

export type TourCreationResult = {
    id: number;
}