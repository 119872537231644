import { get } from "http";
import { Tour, TourIncludeIcon } from "../../common-types";
import "./TourIncludes.css";
import TourIncludesItem from "./TourIncludesItem";
import { Plus } from "lucide-react";

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const {getCloned, setTour, tour} = props;
    return (
        <div className="tour-includes-panel">
            <div className="tour-includes-inner">
                {tour.includes.map((inc, i) => {
                    const handleIconChange = (newIcon: TourIncludeIcon) => {
                        const cloned = getCloned();
                        cloned.includes[i].icon = newIcon;
                        setTour(cloned);
                    }
                    const handleTextChange = (newText: string) => {
                        const cloned = getCloned();
                        cloned.includes[i].label = newText;
                        setTour(cloned);
                    }
                    const handleDelete = () => {
                        const cloned = getCloned();
                        cloned.includes.splice(i, 1);
                        setTour(cloned);
                    }
                    return  (
                        <TourIncludesItem
                            onIconChange={handleIconChange}
                            onTextChange={handleTextChange}
                            include={inc}
                            onDelete={handleDelete}
                        />
                    )
                })}
            </div>
            <button onClick={() => {
                const cloned = getCloned();
                cloned.includes.push({
                    icon: TourIncludeIcon.Bus,
                    label: "New Include"
                })
                setTour(cloned);
            }} className="tour-add-include-btn">
                <Plus />
                Ekle
            </button>
        </div>
    )
}