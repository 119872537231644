import { Tour } from '../../common-types';
import Toggle from '../../components/toggle/Toggle';
import './TourGeneralPanel.css';
import TourImageInput from './TourImageInput';
import TourInputGroup from './TourInputGroup';

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const {getCloned, setTour, tour} = props;
    const handleTitleChange = (newTitle: string) => {
        const newTour = getCloned();
        newTour.title = newTitle;
        setTour(newTour);
    }
    const handleDescriptionChange = (newDescription: string) => {
        const newTour = getCloned();
        newTour.description = newDescription;
        setTour(newTour);
    }
    const handlePlaceChange = (newPlace: string) => {
        const newTour = getCloned();
        newTour.place = newPlace;
        setTour(newTour);
    }
    const handleDurationChange = (newDuration: string) => {
        const newTour = getCloned();
        newTour.duration = newDuration;
        setTour(newTour);
    }
    return (
        <div className='tour-general-panel'>
            <TourInputGroup>
            <label htmlFor='tour-title'>Tur Başlığı</label>
            <input
                id='tour-title'
                type='text'
                value={tour.title}
                placeholder='Tur Başlığı'
                onChange={(e) => handleTitleChange(e.target.value)}
            />
            </TourInputGroup>
            <TourInputGroup>
            <label htmlFor='tour-description'>Tur Açıklaması</label>
            <textarea
                id='tour-description'
                onChange={(e) => handleDescriptionChange(e.target.value)}
                value={tour.description}
            >
            </textarea>
            </TourInputGroup>
            <TourInputGroup>
                <label htmlFor='tour-place'>Tur Yeri</label>
                <input
                    type='text'
                    id='tour-place'
                    onChange={(e) => {
                        handlePlaceChange(e.target.value)
                    }}
                    value={tour.place}
                />
            </TourInputGroup>
            <TourInputGroup>
                <label htmlFor='tour-duration'>Tur Uzunluğu</label>
                <input
                    type='text'
                    id='tour-duration'
                    onChange={(e) => {
                        handleDurationChange(e.target.value)
                    }}
                    value={tour.duration ?? ""}
                />
            </TourInputGroup>
            <TourInputGroup isHorizontal={true}>
                <label htmlFor='tour-thumbnail'>Tur Resmi</label>
                <TourImageInput imageUrl={tour.thumbnail} onImageUploaded={(url) => {
                    const cloned = getCloned();
                    cloned.thumbnail = url;
                    setTour(cloned);
                }} />
            </TourInputGroup>
            <div className='tour-price-group'>
                <label>Asıl fiyat</label>
                <div className='tour-input-with-unit'>
                    <div className='tour-input-unit'>{tour.price.currency}</div>
                    <input type='number' onChange={(e) => {
                        const newTour = getCloned();
                        newTour.price.amount = parseInt(e.target.value);
                        setTour(newTour);
                    }} min={1} value={isNaN(tour.price.amount) ? "" : tour.price.amount} step={1} />
                </div>
                <div className='tour-apply-discount'>
                    <label htmlFor='tour-discount-toggle'>İndirim Uygula</label>
                    <Toggle
                        id='tour-discount-toggle'
                        value={tour.price.discountedAmount != undefined}
                        onChange={(v) => {
                            const newTour = getCloned();
                            if(v) {
                                newTour.price.discountedAmount = newTour.price.amount - 1;
                            } else {
                                delete newTour.price.discountedAmount;
                            }
                            setTour(newTour);
                        }}
                    />
                </div>
                {tour.price.discountedAmount != undefined && (
                    <>
                    <label>İndirimli fiyat</label>
                    <div className='tour-input-with-unit'>
                        <div className='tour-input-unit'>{tour.price.currency}</div>
                        <input
                            type='number'
                            min={1}
                            value={tour.price.discountedAmount}
                            step={1}
                            onChange={(e) => {
                                const newTour = getCloned();
                                newTour.price.discountedAmount = parseInt(e.target.value);
                                setTour(newTour);
                            }}
                        />
                    </div>
                    </>
                )}
                </div>
        </div>
    )
}