import './SidebarButton.css';

export default function (props: {
    label: string;
    icon?: string;
    onClick?: () => void;
    enabled: boolean;
}) {
    const className = `sidebar-button ${props.enabled ? "enabled" : "disabled"}`;
    return (
        <div onClick={props.onClick} className={className}>
            {props.label}
        </div>
    );
}