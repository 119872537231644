import { ReactNode, useEffect, useState } from "react";
import Panel from "./Panel";
import { PanelsContext } from "../../context/PanelsContext";

export default function(props: {
    children?: ReactNode,
    currentPanelKey: string,
    className?: string,
    panelsInner: {
        key: string,
        component: JSX.Element
    }[],
    innerClassName?: string,
    onPanelChange: (newPanelKey: string) => void,
}) {
    const [currentPanelKey, setCurrentPanelKey] = useState(props.currentPanelKey);
    const [oldPanelKey, setOldPanelKey] = useState<string | null>(null);
    const fullClassName = `panel-container ${props.className ?? ""}`;
    const currentIndex = props.panelsInner.findIndex((panelInner) => panelInner.key === currentPanelKey);
    const oldIndex = oldPanelKey ? props.panelsInner.findIndex((panelInner) => panelInner.key === oldPanelKey) : null;
    const animation = !oldIndex || currentIndex > oldIndex ? "rtl" : "ltr";
    const foundPanel = props.panelsInner.find((panelInner) => panelInner.key === currentPanelKey);
    useEffect(() => {
        if(currentPanelKey !== props.currentPanelKey) {
            setOldPanelKey(currentPanelKey);
            setCurrentPanelKey(props.currentPanelKey);
        }
    }, [props.currentPanelKey])
    return (
        <div className={fullClassName}>
            <Panel
            animation={animation}
            className={props.innerClassName ?? ""}
            key={currentPanelKey}
            >
                {foundPanel.component}
            </Panel>
        </div>
    );
}
