import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import { ApiErrorResult, Tour } from "../../common-types";
import useCommon from "../../hooks/useCommon";
import Sidebar from "../../components/bars/Sidebar";
import { Plus } from "lucide-react";
import TourListItem from "../../components/tour-list-item/TourListItem";
import "./Tours.css";
import { TourCreationResult } from "../../moderator-types";

export default function(props: {
    onTourEdit: (tourId: number) => void
}) {
    const api = useApi();
    const common = useCommon();
    const qryResult = useQuery({
        queryKey: ["tours"],
        queryFn: async () => {
            common.setLoading(true);
            const tours = await api.getTours();
            common.setLoading(false);
            const toursAsError = tours as ApiErrorResult;
            if(toursAsError.isError) {
                return [];
            }
            const asTours = tours as Tour[];
            asTours.sort((a, b) => a.id - b.id);
            return asTours;
        }
    });
    const isFetched = qryResult.isFetched && !!qryResult.data;
    const createTourHandler = async () => {
        common.setLoading(true);
        const newTour = await api.createTour();
        common.setLoading(false);
        const asError = newTour as ApiErrorResult;
        if(asError.isError) {
            return;
        }
        const asSuccess = newTour as TourCreationResult;
        qryResult.refetch();
        props.onTourEdit(asSuccess.id);
    }
    return (
        <div className="tours-panel">
            {isFetched && <>
                <Sidebar>
                    <button onClick={() => {
                        createTourHandler();
                    }} className="new-tour-btn"><Plus /> Yeni Tur</button>
                </Sidebar>
                <div className="tours-grid">
                    {qryResult.data.map(tour => <TourListItem
                        key={tour.id}
                        tour={tour}
                        onClick={() => props.onTourEdit(tour.id)}
                    />)}
                </div>
            </>}
        </div>
    )
}