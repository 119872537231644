import { TourInclude, TourIncludeIcon } from "../../common-types";
import TourIncludesIconSelect from "./TourIncludesIconSelect";
import './TourIncludesItem.css';

export default function(props: {
    onIconChange: (newIcon: TourIncludeIcon) => void,
    onTextChange: (newText: string) => void,
    onDelete: () => void,
    include: TourInclude
}) {
    const {onIconChange, onDelete, onTextChange, include} = props;
    return (
        <div className="tour-includes-item">
            <TourIncludesIconSelect icon={include.icon} onChange={onIconChange} />
            <input type="text" value={include.label} onChange={(e) => {
                onTextChange(e.target.value);
            }} />
            <button onClick={() => {
                onDelete();
            }}>Sil</button>
        </div>
    )
}