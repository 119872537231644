import Spinner from '../components/Spinner';
import './Loading.css';

export default function(props: {enabled: boolean}) {
    const isEnabled = props.enabled ?? false;
    return isEnabled ? (
        <div className="loading-overlay">
            <Spinner />
        </div>
    ) : null;
}