import { usePanels } from '../../hooks/usePanels';
import './DashboardPages.css';
export default function(props: {
    activePage: string,
    pages: {
        key: string,
        label: string,
        icon: string,
    }[]
}) {
    const panels = usePanels();
    return (
        <div className="bar-pages">
            {props.pages.map(page => (
                <div
                    key={page.key}
                    className={`page-item ${props.activePage == page.key ? "active": ""}`}
                    onClick={() => panels.handleChange(page.key)}
                >
                    <div className='icon-container'><i className={`page-icon ${page.icon}`}></i></div>
                    <span className="page-label">{page.label}</span>
                </div>
            ))}
        </div>
    )
}