import React from "react";
import './TourInputGroup.css';

export default function(props: {
    children: React.ReactNode,
    className?: string,
    isHorizontal?: boolean
}) {
    const newClassName = ["tour-input-group", props.className, props.isHorizontal && "horizontal"]
    .filter(t => !!t)
    .join(" ");
    return (
        <div className={newClassName}>
            {props.children}
        </div>
    )
}