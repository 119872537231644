import { useState } from 'react';
import { TourDateEntry as TDE, TourDateEntryType } from '../../common-types';
import './TourDateNewEntry.css';
import TourDateEntry from './TourDateEntry';
import { Plus } from 'lucide-react';

export default function (props: {
    onCreated: (dateEntry: TDE) => void,
}) {
    const defaultEntry: TDE = {
        type: TourDateEntryType.EveryDay,
        exclude: false,
        data: {
            time: 8 * 60
        }
    }
    const [entry, setEntry] = useState<TDE>(defaultEntry);
    return (
        <TourDateEntry
            dateEntry={entry}
            onUpdate={(updatedEntry) => {
                setEntry(updatedEntry);
            }}
            onRemove={undefined}
            canRemove={false}
            className='new'
        >
            <button className='add-tour-date-entry' onClick={() => {
                props.onCreated(entry);
                setEntry(defaultEntry);
            }}><Plus/> Ekle</button>
        </TourDateEntry>
    )
}