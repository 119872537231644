import './ErrorMessage.css';

export default function(props: {
    title?: string;
    message?: string;
}) {
    const title = props.title || "Hata";
    const message = props.message || "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.";
    return (
        <div className="error-message">
            <div className="error-message-inner">
                <div className='error-title'>{title}</div>
                <div className='error-description'>{message}</div>
            </div>
        </div>
    );
}