import { useState } from "react";
import { TicketStatusType } from "../../common-types";
import { useApi } from "../../hooks/useApi";
import { ModeratorTicket } from "../../moderator-types";
import "./TicketHistory.css";

const statusNames = {
    [TicketStatusType.Open]: "Bilet oluşturuldu.",
    [TicketStatusType.Booked]: "Bilet rezerve edildi.",
    [TicketStatusType.CancelledModerator]: "Bilet moderatör tarafından iptal edildi.",
    [TicketStatusType.CancelledUser]: "Bilet kullanıcı tarafından iptal edildi.",
    [TicketStatusType.Confirmed]: "Bilet Onaylandı",
    [TicketStatusType.Finished]: "Bilet Tamamlandı",
    [TicketStatusType.Reached]: "Kullanıcıya ulaşıldı",
}

const statusPrompts = {
    [TicketStatusType.Open]: "Bilet oluşturulmuş hale getir.",
    [TicketStatusType.Booked]: "Bileti rezerve et.",
    [TicketStatusType.CancelledModerator]: "Bileti iptal et.",
    [TicketStatusType.CancelledUser]: "Bileti iptal et.",
    [TicketStatusType.Confirmed]: "Bileti onayla.",
    [TicketStatusType.Finished]: "Bileti tamamla.",
    [TicketStatusType.Reached]: "Kullanıcıya ulaşıldı olarak işaretle.",
}

const statusMaps = {
    [TicketStatusType.Open]: [TicketStatusType.Booked, TicketStatusType.CancelledModerator],
    [TicketStatusType.Booked]: [TicketStatusType.Reached, TicketStatusType.CancelledModerator],
    [TicketStatusType.CancelledModerator]: [],
    [TicketStatusType.CancelledUser]: [],
    [TicketStatusType.Confirmed]: [TicketStatusType.Finished, TicketStatusType.CancelledModerator],
    [TicketStatusType.Finished]: [],
    [TicketStatusType.Reached]: [TicketStatusType.Confirmed, TicketStatusType.CancelledModerator],
}

export default function(props: {
    ticket: ModeratorTicket,
    refreshHandler: () => void
})
{
    const [cancelReason, setCancelReason] = useState("");
    const ticket = props.ticket.ticket;
    const assigned = props.ticket.assignedSelf ?? false;
    const lastStatus = ticket.statusHistory.at(-1);
    const nextStatuses = statusMaps[lastStatus.type];
    const canChangeStatus = nextStatuses.length > 0 && assigned;
    const canCancel = nextStatuses.includes(TicketStatusType.CancelledModerator) && assigned;
    const nextStatus = canChangeStatus ? nextStatuses.filter(s => s != TicketStatusType.CancelledModerator)[0] : null;
    const api = useApi();
    const handleStatusChange = (status: TicketStatusType, message?: string) => {
        api.changeTicketStatus(ticket.id, status, message).then(() => {
            props.refreshHandler();
        });
    }
    return (
        <div className="ticket-history">
            <div className="ticket-history-entries">
                {ticket.statusHistory.map((status, index) => {
                    let statusName = statusNames[status.type];
                    return (
                        <>
                            <div className="ticket-history-entry-date">{status.date}</div>
                            <div className={`status-label status-color-${status.type}`}>
                                <i className={`status-icon ${status.type}`}></i>{
                                statusName}
                                </div>
                        </>
                    )
                })}
            </div>
            {canChangeStatus && <div className="ticket-next-status-container">
                <button onClick={() => handleStatusChange(nextStatus!)} className={`ticket-next-status-button status-color-${nextStatus!}`}>
                    <i className={`status-icon ${nextStatus!}`}></i>
                    {statusPrompts[nextStatus!]}
                </button>
            </div>}
            {canCancel && <div className="ticket-cancel-container">
                <label className="ticket-cancel-label" htmlFor="cancelReason">İptal sebebi:</label>
                <textarea
                    onChange={(e) => setCancelReason(e.target.value)}
                    value={cancelReason}
                    className="ticket-cancel-description"
                    name="reason"
                    id="cancelReason"
                    placeholder="Kullanıcı iptal konusunda bu mesajı görecek."
                />
                <button onClick={() => handleStatusChange(TicketStatusType.CancelledModerator, cancelReason)} className="ticket-cancel-button status-color-cancelled_moderator status-apply-color">
                    {statusPrompts[TicketStatusType.CancelledModerator]}
                </button>
            </div>}
        </div>
    )
}