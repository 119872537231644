import { useEffect, useState } from 'react';
import './CurrencyInput.css';
import { createClass } from '../../utils/create-class';

export default function(props: {
    currency: string,
    value: number,
    onChange: (value: number, elem: HTMLElement) => void,
    containerClassName?: string,
    currencyClassName?: string,
    inputClassName?: string,
    autoFocus?: boolean
}) {
    const { value, onChange } = props;
    const [valueStr, setValueStr] = useState(value?.toString() ?? "");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const intValue = parseInt(value);
        const isNaN = Number.isNaN(intValue);
        if(isNaN) {
            setValueStr(value);
            return;
        }
        onChange(intValue, e.target);
    }
    useEffect(() => {
        setValueStr(value?.toString() ?? "");
    }, [value]);
    return (
        <div className={createClass("currency-input", props.containerClassName)}>
            <div className={createClass('currency-symbol', props.containerClassName)}>{props.currency}</div>
        <input
            className={createClass('currency-input', props.inputClassName)}
            type='number'
            value={valueStr}
            onChange={handleChange}
            autoFocus={props.autoFocus}
        />
        </div>
    )
}