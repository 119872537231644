import { Tour } from "../../common-types";
import "./TourListItem.css";

export default function(props: {
    tour: Tour,
    onClick: () => void
}) {
    const {tour} = props;
    const isThumbnailValid = tour.thumbnail && tour.thumbnail.trim().length > 0;
    const thumbnailUrl = isThumbnailValid ? tour.thumbnail : "/placeholder/img.webp";
    return (
        <div onClick={props.onClick} className="tour-li">
            <img className="tour-li-image" src={thumbnailUrl} />
            <div className="tour-li-info">
                <div className="tour-li-title">{tour.title}</div>
                <div className="tour-li-description">{tour.description}</div>
                <div className="tour-li-place">{tour.place}</div>
            </div>
            <div className="tour-li-price">
                {tour.price.amount} {tour.price.currency}
            </div>
        </div>
    )
}