import { useContext } from "react"
import { PanelsContext } from "../context/PanelsContext"

export const usePanels = () => {
    const panelsCtx = useContext(PanelsContext);
    return {
        handleChange: panelsCtx.handleChange as (key: string) => void,
        current: panelsCtx.current,
        pushPanel: panelsCtx.pushPanel as (key: string) => void,
        popPanel: panelsCtx.popPanel as () => void
    }
}

export type PanelsHook = ReturnType<typeof usePanels>