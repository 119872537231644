import { useSession } from "./hooks/useSession";
import Dashboard from "./pages/Dashboard/Dashboard";
import LogIn from "./pages/LogIn/LogIn";

export default function(props) {
    const session = useSession();
    return (
        <>
        {!session.isLoggedIn && <LogIn />}
        {session.isLoggedIn && <Dashboard />}
        </>
    )
}