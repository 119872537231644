import CommonProvider from './providers/CommonProvider';
import SessionProvider from './providers/SessionProvider';
import ApiProvider from './providers/ApiProvider';
import Entry from './Entry';
import './App.css';

import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

const queryClient = new QueryClient();

function App() {
  return (
    <CommonProvider>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <ApiProvider>
            <Entry />
          </ApiProvider>
        </SessionProvider>
      </QueryClientProvider>
    </CommonProvider>
  );
}

export default App;
