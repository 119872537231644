import { BedDouble, BookUser, BusFront, CableCar, CalendarFold, Camera, Coffee, Gift, Landmark, Martini, Moon, PartyPopper, Ship, ShoppingBag, Sun, Utensils, Waves } from "lucide-react";
import { TourIncludeIcon } from "../../common-types";

const jsxMapper = {
    [TourIncludeIcon.Food]: <Utensils />,
    [TourIncludeIcon.Drink]: <Coffee />,
    [TourIncludeIcon.Alcohol]: <Martini />,
    [TourIncludeIcon.Bus]: <BusFront />,
    [TourIncludeIcon.Boat]: <Ship />,
    [TourIncludeIcon.CableCar]: <CableCar />,
    [TourIncludeIcon.Camera]: <Camera />,
    [TourIncludeIcon.Book]: <BookUser />,
    [TourIncludeIcon.Bed]: <BedDouble />,
    [TourIncludeIcon.Moon]: <Moon />,
    [TourIncludeIcon.Sun]: <Sun />,
    [TourIncludeIcon.Gift]: <Gift />,
    [TourIncludeIcon.MultiDay]: <CalendarFold />,
    [TourIncludeIcon.Museum]: <Landmark />,
    [TourIncludeIcon.Party]: <PartyPopper />,
    [TourIncludeIcon.Shopping]: <ShoppingBag />,
    [TourIncludeIcon.Waves]: <Waves />
}

export default function(props: {
    icon: TourIncludeIcon
}) {
    return jsxMapper[props.icon];
}