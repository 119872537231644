import './Toggle.css';

export default function(props: {
    value: boolean,
    className?: string,
    id?: string,
    onChange: (newValue: boolean) => void
}) {
    const newClassName = ["input-toggle", props.className, props.value && "enabled"].filter(t => !!t).join(" ");
    return (
        <div id={props.id} onClick={() => props.onChange(!props.value)} className={newClassName}>
            <div className="input-toggle-spacing"></div>
            <div className="input-toggle-switch"></div>
        </div>
    )
}