import { usePanels } from "../../hooks/usePanels";
import LogOut from "../LogOut";
import UserBlock from "../UserBlock";
import DashboardPages from "./DashboardPages";
import "./DashboardTop.css";

export default function(props: {
    context?: "panels" | "edit"
}) {
    const panels = usePanels();
    const isEditTicket = panels.current === "edit-ticket";
    const isEditTour = panels.current === "edit-tour";
    const isEdit = isEditTicket || isEditTour;
    const editLabel = !isEditTour ? "Bilet Düzenleme" : "Tur Düzenleme";
    return (
        <div className="dashboard-top">
            <div className="dashboard-name"><strong>ODYSSEY </strong>| PANEL</div>
            <div className="dashboard-padding"></div>
            {isEdit ? <div className="dashboard-edit">
                <button className="edit-back" onClick={() => {
                    panels.popPanel();
                }}>
                    Geri Dön
                </button>
                <div>{editLabel}</div>
            </div> :
            <DashboardPages pages={[
                {
                    key: "tickets",
                    label: "Biletler",
                    icon: "ticket"
                },
                {
                    key: "tours",
                    label: "Turlar",
                    icon: "world"
                },
                {
                    key: "prices",
                    label: "Fiyatlar",
                    icon: "price"
                }
            ]}
            activePage={panels.current}
            />
            }
            <div className="dashboard-padding"></div>
            <UserBlock />
            <LogOut />
        </div>
    );
}