import { useState } from "react";
import { useApi } from "../../hooks/useApi";
import Authenticate from "./Authenticate";
import "./LogIn.css";
import ErrorMessage from "../../components/ErrorMessage";

export default function(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <div className="login-panel">
            {authenticating ? <Authenticate
                uname={email}
                pass={password}
             errorHandler={(msg) => {
                    setErrorMessage(msg);
                    setAuthenticating(false);
             }} 
             /> :
            <div className="login-container">
                {errorMessage.trim() !== "" && <ErrorMessage message={errorMessage}/>}
                <h2>Giriş</h2>
                <input type="text" placeholder="Kullanıcı Adı" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button onClick={() => setAuthenticating(true)} >Giriş Yap</button>
            </div>
            }
        </div>
    );
}