import { useState } from "react";

export const useCreatePanels = (defaultKey: string) => {

    const [currentPanel, setCurrentPanel] = useState(defaultKey);
    const [pushedPanels, setPushedPanels] = useState<string[]>([defaultKey]);
    const handlePanelPop = () => {
        const singleLeft = pushedPanels.length === 1;
        const newPanels =  singleLeft ? [defaultKey] : [...pushedPanels].slice(0, -1);
        setPushedPanels(newPanels);
        const newPanel = newPanels[newPanels.length - 1];
        setCurrentPanel(newPanel);
    }
    const handlePanelPush = (newPanelKey: string) => {
        const newPanels = [...pushedPanels, newPanelKey];
        setPushedPanels(newPanels);
        setCurrentPanel(newPanelKey);
    }
    const handlePanelChange = (newPanelKey: string) => {
        if(newPanelKey === currentPanel) {
            return;
        }
        const newPanels = [...pushedPanels];
        newPanels[newPanels.length - 1] = newPanelKey;
        setPushedPanels(newPanels);
        setCurrentPanel(newPanelKey);
    }
    return {
        current: currentPanel,
        handleChange: handlePanelChange,
        pushPanel: handlePanelPush,
        popPanel: handlePanelPop
    }
}