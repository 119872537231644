import { Api } from "../api";
import { ApiErrorResult } from "../common-types";
import { ImageUploadResponse } from "../moderator-types";

type imageUploadEvents = {
    onFileRead?: () => void,
    onFileReadError?: () => void,
    onApiError?: () => void,
    onAnyError: () => void,
    onSuccess: (url: string) => void
}

export const handleImageUpload = async (file: File, api: Api, events: imageUploadEvents) => {
    const readContent: string = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if(!e.target?.result)
            {
                resolve(null);
                return;
            }
            resolve(e.target.result as string);
        }
        reader.onerror = () => {
            resolve(null);
        }
        reader.onabort = () => {
            resolve(null);
        }
        reader.readAsDataURL(file);
    });
    if(!readContent)
    {
        events.onFileReadError?.();
        events.onAnyError();
        return;
    }
    events.onFileRead?.();
    const fileName = file.name;
    const uploadResult = await api.uploadImage({
        fileName,
        data: readContent
    });
    const uploadAsError = uploadResult as ApiErrorResult;
    if(uploadAsError.isError)
    {
        events.onApiError?.();
        events.onAnyError();
        return;
    }
    const uploadAsSuccess = uploadResult as ImageUploadResponse;
    events.onSuccess(uploadAsSuccess.url);
}